import axios from 'axios';

export const baseUrl = 'https://sapb1prod.mills.com.br/';

export const axiosApi = () => {

    const token = localStorage.getItem('token');

    const api = axios.create({
        baseURL: baseUrl,
        headers: {
            'Authorization': `bearer ${token}`
        }
    });

    return api;

}

export default axiosApi;