import React from 'react';

import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import Page from '../../components/Page';
import { isTriengel } from '../../services/Handlers';
import logoTriengel from '../../static/images/mills.svg';

export default function Home() {

    function saibaMaisTriengel() {
        window.open('https://www.mills.com.br/');
    }

    function saibaMaisHengel() {
        window.open('http://www.hengel.com.br');
    }

    return (

        <Page title="" showSpinner={false}>

            <div style={{ maxWidth: '1180px', margin: 'auto' }}>
                {!isTriengel ? <></> :
                    <Card style={{ margin: '10px' }}>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <h2>Mills</h2>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <p>
                                    Somos uma empresa que há 70 anos lidera o mercado, pioneira na locação de equipamentos para execução de trabalhos em altura, com a maior frota de plataformas elevatórias da América Latina. Reconhecida pela segurança, confiança, qualidade e abrangência, agora também em máquinas pesadas (linha amarela).
                                </p>
                                <p>
                                    Valorizamos pessoas e simplificamos os desafios dos nossos clientes, com o objetivo de gerar valor em longo prazo de forma sustentável.
                                </p>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <img alt="Mills" height="170" style={{ margin: 'auto', display: 'block' }}
                                    src={logoTriengel} />
                            </div>
                            <div className="p-col-12">
                                <Button onClick={() => saibaMaisTriengel()} label="Saiba Mais" icon="fa fa-caret-right" />
                            </div>

                        </div>
                    </Card>
                }

                {isTriengel ? <></> :
                    <Card style={{ margin: '10px' }}>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <h2>Hengel</h2>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <p>
                                    Desde 1985 atuamos no seguimento de transporte e comercialização de biomassa da agroindústria sucroalcooleira e agroenergia creditando altos índices de satisfação no mercado.
                                </p>
                                <p>
                                    Com foco nas regiões sudeste e centro-oeste do Brasil, atendemos indústrias, usinas e frigoríficos com transporte e comercialização de biomassa voltada para geração de energia renovável.
                                </p>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <img alt="Hengel" height="80" style={{ margin: 'auto', display: 'block' }}
                                    src="https://www.hengel.com.br/imagens/logo.png" />
                            </div>
                            <div className="p-col-12">
                                <Button onClick={() => saibaMaisHengel()} label="Saiba Mais" icon="fa fa-caret-right" />
                            </div>

                        </div>
                    </Card>
                }

            </div>

        </Page>

    )

}