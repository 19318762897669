import React from 'react';
import './Login.css';

import background from "../../static/images/thisBanner.jpeg";
import backgroundTriengel from "../../static/images/thisBannerTriengel.jpeg";
import logo from '../../static/images/thislogo.jpg';
import logoTriengel from '../../static/images/mills-1.jpg';
import sap from '../../static/images/sap.png';

import LoginForm from './LoginForm';

import { isTriengel } from '../../services/Handlers';

export default function Login() {

    return (
        <div className="containerLogin" style={containerLogin}>

            <div className="containerBluer">
                <div className="login-wrap">
                    <div className="login-html">
                        <div className="login-form">

                            <img alt="logo" className="imgHengel" src={isTriengel ? logoTriengel : logo} />

                            <label style={{ display: 'block' }} className="tab">
                                Bem-Vindo
                            </label>

                            <LoginForm />

                            <div className="hrLogin" />

                            <div style={{ marginLeft: 'auto', marginRight: 'auto', display: 'table' }}>
                                <img alt="sap" className="sapimg footerSAP" src={sap} />
                            </div>

                            <div className="hrLogin" />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

const containerLogin = {
    backgroundImage: isTriengel ? `url(${backgroundTriengel})` : `url(${background})`
}