import React from 'react';

import Loader from 'react-loader-spinner'

import MenuBar from './MenuBar';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

export default function (props) {

    return (
        <React.Fragment>

            <MenuBar />

            <div className="container">

                {
                    props.showSpinner ?
                        <div id="cover-spin">
                            <div className="loadingSpinner">
                                <Loader type="Bars" color="#324ea8" height={100} width={100} />
                            </div>
                        </div>
                        : ''
                }
                
                <div className="p-grid">
                    <div className="p-col-12 p-md-8" >
                        <h1>{props.title}</h1>
                    </div>
                    <div className="p-col-12 p-md-4" >
                        {props.buttonAditional}
                    </div>
                </div>
                
                <div>
                    {props.children}
                </div>

            </div>

            <div className="footerPagina" />

        </React.Fragment>
    );
};
